import {
  AnchorModule,
  AvailabilityCheckerModule,
  AvailabilityCheckerResultsModule,
  ButtonCloudModule,
  CalloutModule,
  CardModule,
  ConsultationAppointmentBookingModule,
  ContentModule,
  DynamicContentModule,
  GalleryModule,
  GridModule,
  HelpSnippetModule,
  HelpSnippetsModule,
  HighlightsModule,
  OrderSummaryModule,
  SearchInputModule,
  SupportLinkModule,
  SupportLinksModule,
} from '../Modules'

export default {
  anchor: AnchorModule,
  availabilityChecker: AvailabilityCheckerModule,
  availabilityCheckerResults: AvailabilityCheckerResultsModule,
  buttonCloud: ButtonCloudModule,
  callout: CalloutModule,
  card: CardModule,
  consultationAppointmentBooking: ConsultationAppointmentBookingModule,
  content: ContentModule,
  dynamicContent: DynamicContentModule,
  gallery: GalleryModule,
  grid: GridModule,
  helpSnippet: HelpSnippetModule,
  helpSnippets: HelpSnippetsModule,
  highlights: HighlightsModule,
  orderSummary: OrderSummaryModule,
  searchInput: SearchInputModule,
  supportLink: SupportLinkModule,
  supportLinks: SupportLinksModule,
}
