import sectionModules from '../../Sections/supported-section-modules'

const SectionModule = ({ modules }) => {
  if (!modules) return null

  return modules
    .filter((module) => !!sectionModules[module.contentType])
    .map((module) => {
      const Component = sectionModules[module.contentType]
      return (
        <div key={module.id} className="mb-lg">
          <Component {...module} />
        </div>
      )
    })
}

export default SectionModule
