import { useMemo } from 'react'
import {
  Page,
  useColourData,
} from '@sh24/ui-components'
import {
  AnchorModule,
  ButtonCloudModule,
  ConsultationAppointmentBookingModule,
  ContentModule,
  DynamicContentModule,
  GalleryModule,
  GridModule,
  HelpSnippetsModule,
  HighlightsModule,
  OrderSummaryModule,
  SearchInputModule,
  SupportLinksModule,
  AvailabilityCheckerModule,
  ContraceptionComparisonToolModule,
  AvailabilityCheckerResultsModule,
  LoginModule,
  VerifyPhoneNumberModule,
  ResetPasswordEmailModule,
  ResetPasswordVerifyEmailModule,
  SignUpEmailModule,
  SignUpPasswordModule,
  SignUpVerifyEmailModule,
  SignUpPhoneNumberModule,
  SignUpVerifyPhoneNumberModule,
  MfaModule,
  MfaAddAuthenticatorModule,
} from '../../components/Modules'
import SectionContext from '../../contexts/section-context'
import SectionModule from '../../components/Modules/SectionModule/section-module'
import { getBackgroundColour, getDecoration } from '../config/helpers'

const moduleMap = {
  // contentful modules:
  buttonCloud: ButtonCloudModule,
  consultationAppointmentBooking: ConsultationAppointmentBookingModule,
  content: ContentModule,
  dynamicContent: DynamicContentModule,
  gallery: GalleryModule,
  grid: GridModule,
  helpSnippets: HelpSnippetsModule,
  highlights: HighlightsModule,
  orderSummary: OrderSummaryModule,
  searchInput: SearchInputModule,
  supportLinks: SupportLinksModule,
  availabilityChecker: AvailabilityCheckerModule,
  contraceptionComparisonTool: ContraceptionComparisonToolModule,
  availabilityCheckerResults: AvailabilityCheckerResultsModule,
  section: SectionModule,
  // programmatic modules:
  login: LoginModule,
  verifyPhoneNumber: VerifyPhoneNumberModule,
  resetPasswordEmail: ResetPasswordEmailModule,
  resetPasswordVerifyEmail: ResetPasswordVerifyEmailModule,
  signUpEmail: SignUpEmailModule,
  signUpPassword: SignUpPasswordModule,
  signUpVerifyEmail: SignUpVerifyEmailModule,
  signUpPhoneNumber: SignUpPhoneNumberModule,
  signUpVerifyPhoneNumber: SignUpVerifyPhoneNumberModule,
  mfa: MfaModule,
  mfaAddAuthenticator: MfaAddAuthenticatorModule,
}

const Section = ({
  config,
  modules,
  renderModules,
}) => {
  let sectionIndex = 0

  return modules?.map((module, index) => {
    const Component = moduleMap[module.contentType] || null
    if (!Component) return null

    const last = index === modules.length - 1

    const backgroundColour = getBackgroundColour({
      module,
      config,
      index: sectionIndex,
      last,
    })

    const nextBackgroundColour = getBackgroundColour({
      module: modules[index + 1],
      config,
      index: sectionIndex + 1,
      last: (index + 1) === modules.length - 1,
    })

    const decoration = getDecoration({
      decorations: config.decorations,
      backgroundColour,
      nextBackgroundColour,
      index: sectionIndex,
      last,
    })

    const options = config.modules?.[module.contentType] || {}

    const prevModule = modules[index - 1]
    sectionIndex += 1

    const colourData = useColourData(backgroundColour)

    const sectionContextProviderValue = useMemo(() => ({
      backgroundColour,
      decoration,
      isDark: colourData.isDark,
    }), [backgroundColour, decoration])

    return (
      <SectionContext.Provider
        key={module.id}
        value={sectionContextProviderValue}
      >
        <Page.Section
          backgroundColour={backgroundColour}
          decoration={decoration}
          hidden={!renderModules[module.id]}
          isDark={colourData.isDark}
        >
          <Page.Content>
            {prevModule?.contentType === 'anchor' ? <AnchorModule {...prevModule} /> : null}
            <Component {...module} {...options} />
          </Page.Content>
        </Page.Section>
      </SectionContext.Provider>
    )
  })
}

export default Section
