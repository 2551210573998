interface TemplateConfig {
  backgroundColours: {
    first: string
    middle: string[]
    last: string
  }
  backgroundOverrides: Record<string, string>
  decorations: {
    first: string
    middle: string[]
  }
}

interface Module {
  contentType: string
  type?: string
  backgroundColour?: string | null
}

/**
 * Determine the correct colour for the given module section based on
 * patterns defined in config.
 *
 * Can be overriden for specific modules.
 */
export const getBackgroundColour = ({
  config,
  module,
  index,
  last,
}: {
  config: TemplateConfig,
  module: Module
  index: number
  last: boolean
}) => {
  if (module?.contentType === 'section' && module?.backgroundColour) return module.backgroundColour

  const {
    backgroundOverrides,
    backgroundColours,
  } = config

  const bgOverride = backgroundOverrides?.[module?.type || module?.contentType]

  if (bgOverride) return bgOverride

  const totalColours = backgroundColours.middle.length
  const colourIndex = index % totalColours

  return last ? backgroundColours.last : backgroundColours.middle[colourIndex]
}

/**
 * Determine the correct decoration for the given module section based on
 * patterns defined in config.
 *
 * Ensures the last section has no decoration.
 */
export const getDecoration = ({
  decorations,
  backgroundColour,
  nextBackgroundColour,
  index,
  last,
}: {
  decorations: TemplateConfig['decorations']
  backgroundColour: string
  nextBackgroundColour: string
  index: number
  last: boolean
}) => {
  if (nextBackgroundColour === backgroundColour) return 'None'

  const totalDecorations = decorations.middle.length
  const decorationIndex = index % totalDecorations

  return last ? 'None' : decorations.middle[decorationIndex]
}
