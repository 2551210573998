import Head from 'next/head'
import { PageNavigation } from '@sh24/ui-components'
import { useEffect, useMemo, useState } from 'react'
import {
  CURRENT_JOURNEY_KEY,
  getSession,
  clearSession,
} from '../../order-journeys/_v2/state/actions'
import { trackEcommercePurchase } from '../../order-journeys/_v2/tracking'
import SEO from '../../components/SEO/seo'
import { useAppContext } from '../../contexts/app-context'
import PageContext from '../../contexts/page-context'
import JSONSchema from '../../components/JSONSchema/json-schema'
import buildPageNav from '../../utils/build-page-nav'
import useTranslations from '../../utils/use-translations'
import lookupTranslations from '../../utils/lookup-translations'
import templateConfig from '../config'
import Headers from '../headers'
import Sections from './sections'

const GenericPageTemplate = ({ page, backPath }) => {
  const { appContext } = useAppContext()
  const translations = useTranslations()
  const pageNavTranslations = lookupTranslations({ translations, lookup: 'pageNavigation.' })
  const pageNav = buildPageNav(page, pageNavTranslations)
  const config = templateConfig[page.template] || templateConfig.template_1

  useEffect(() => {
    if (page.pageType === 'completion') {
      const sessionKey = window.sessionStorage.getItem(CURRENT_JOURNEY_KEY)
      const journeyState = getSession({ sessionKey })

      if (!journeyState && !appContext?.preview) window.location.assign('/')

      if (journeyState?.context?.sessionKey === sessionKey) {
        trackEcommercePurchase(journeyState.context)
        clearSession({ sessionKey })
      }
    }
  }, [])

  const { modules } = page

  const [renderModules, setRenderModules] = useState(modules && modules.reduce((acc, obj) => {
    acc[obj.id] = true
    return acc
  }, {}))

  const updateRenderModules = (id, shouldRender) => {
    setRenderModules((prev) => ({
      ...prev,
      [id]: shouldRender,
    }))
  }

  const pageContextProviderValue = useMemo(() => ({
    ...page,
    features: {
      moduleHeaders: true,
    },
    updateRenderModules,
  }),
  [page])

  const Header = Headers[page.headerStyle]

  return (
    <PageContext.Provider value={pageContextProviderValue}>
      {page.jsonSchema && <JSONSchema id={page.id} schema={page.jsonSchema} />}
      {page.seo && <SEO seo={page.seo} url={page.url} Head={Head} />}

      {Header && <Header page={page} config={config} backPath={backPath} />}

      <Sections config={config} renderModules={renderModules} modules={modules} />

      {pageNav && (
        <PageNavigation
          prevPageLink={pageNav.prevPageLink}
          nextPageLink={pageNav.nextPageLink}
        />
      )}
    </PageContext.Provider>
  )
}

export default GenericPageTemplate
